exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-klarna-success-js": () => import("./../../../src/pages/klarna-success.js" /* webpackChunkName: "component---src-pages-klarna-success-js" */),
  "component---src-pages-vipps-success-js": () => import("./../../../src/pages/vipps-success.js" /* webpackChunkName: "component---src-pages-vipps-success-js" */),
  "component---src-templates-area-of-use-js": () => import("./../../../src/templates/areaOfUse.js" /* webpackChunkName: "component---src-templates-area-of-use-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-favourites-js": () => import("./../../../src/templates/favourites.js" /* webpackChunkName: "component---src-templates-favourites-js" */),
  "component---src-templates-floor-solution-js": () => import("./../../../src/templates/floorSolution.js" /* webpackChunkName: "component---src-templates-floor-solution-js" */),
  "component---src-templates-guide-js": () => import("./../../../src/templates/guide.js" /* webpackChunkName: "component---src-templates-guide-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-products-company-js": () => import("./../../../src/templates/productsCompany.js" /* webpackChunkName: "component---src-templates-products-company-js" */),
  "component---src-templates-products-private-js": () => import("./../../../src/templates/productsPrivate.js" /* webpackChunkName: "component---src-templates-products-private-js" */),
  "component---src-templates-sharedcart-js": () => import("./../../../src/templates/sharedcart.js" /* webpackChunkName: "component---src-templates-sharedcart-js" */)
}

